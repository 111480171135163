
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body{
	padding-top: 20px;
	padding-bottom: 20px;
}

.header_logo{
	margin-bottom: 20px;
}